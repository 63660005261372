import React, { Component } from 'react';
import appstore from './appstore.svg';
import screenshots from './screenshots@3x.png'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        
        <div style={{ width: '90%', maxWidth: 320, margin: '40px auto' }}>
          
          <div style={{ textAlign: 'center' }}>
            <img width={307} src={screenshots} alt="screenshots"/>
          </div>
          
          <h1 style={{ fontWeight: 400, fontSize: 48, marginTop: 30, marginBottom: 12 }}>Molasses</h1>
          
          <h2 style={{ fontWeight: 400, fontSize: 24, marginTop: 12, marginBottom: 16 }}>Your grocery list.</h2>
          
          <a href="https://itunes.apple.com/us/app/molasses/id1222425162?ls=1&mt=8">
            <img src={appstore} width={120} style={{ marginTop: 16 }} alt="app store badge"/>
          </a>
          
          <div style={{ color: 'grey', marginTop: 80 }}>
            <h3 style={{ fontWeight: 700,  fontSize: 20, textTransform: 'uppercase' }}>Chefs & Authors</h3>
            <p style={{ fontSize: 20 }}>Would you like your recipe featured in Molasses? Featured recipes include an affiliate link to your cookbook on Amazon — it’s a great way of driving book sales. And it’s free.</p>
            <p  style={{ fontSize: 20 }}>Sign up <a href="https://docs.google.com/forms/d/e/1FAIpQLSdYFD6iMr-spLmCqortJg4OdjgCLxfFpumjVsljUM0iCVk_4A/viewform?usp=sf_link">here</a>.</p>
          </div>
          
        </div>
      </div>
    );
  }
}

export default App;
